<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-row items-center mb-2">
                <div class="vx-col md:w-1/2">
                    <TitleScreen></TitleScreen>
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-button
                        class="float-right ml-5"
                        v-if="$acl.check('organization')"
                        @click="addCamera"
                        icon="icon-plus"
                        icon-pack="feather"
                        color="primary">
                        {{ $t('button.addNew') }}
                    </vs-button>
                </div>
            </div>
            <div class="vx-row items-center">
                <div class="vx-col md:w-full">
                    <vs-input class="float-right"
                              v-model="searchQuery"
                              @input="updateSearchQuery" :placeholder="$t('button.search')"/>
                </div>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="organizationBranchList"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>
            <div class="vx-row items-center">
                <div class="vx-col md:w-1/2">
                    <Pagination
                        :itemList="organizationBranchList"
                        :currentPage="currentPage"
                        :paginationPageSize="paginationPageSize"
                        :gridApi="gridApi"
                    />
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-pagination
                        :total="totalPages"
                        :max="maxPageNumbers"
                        v-model="currentPage"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        TitleScreen,
        AgGridVue,

        // Cell Renderer
        Pagination,
        CellRendererActions,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            latitude: 0,
            longitude: 0,
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 100
                },
                {
                    headerName: this.$t('menuOrganization.branchName'),
                    field: 'organizationBranchName',
                    width: 250
                },
                {
                    headerName: this.$t('menuOrganization.phoneNumber'),
                    field: 'phoneNumber',
                    width: 170,
                },
                {
                    headerName: this.$t('menuOrganization.address'),
                    field: 'fullAddress',
                    width: 350
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'actions',
                    width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            organizationBranchList: []
        }
    },
    created() {
        this.initScreen();
        this.$eventBus.$on('i18n', (event) => {
            this.columnDefs[1].headerName = this.$t('menuOrganization.branchName');
            this.columnDefs[2].headerName = this.$t('menuOrganization.phoneNumber');
            this.columnDefs[3].headerName = this.$t('menuOrganization.address');
            this.columnDefs[4].headerName = this.$t('button.actions');
            this.gridOptions.api.setColumnDefs(this.columnDefs);

        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteBranchStatus'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('DELETE_BRANCH_STATUS', false);
            }
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addCamera() {
            this.$router.push('/user/add-organization-branch').catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            let that = this;
            this.$crm.post('/organization-branch/all').then((response) => {
                this.organizationBranchList = response.data;
                that.$store.dispatch('updateBranchListInfo', response.data);
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
